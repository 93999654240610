var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"RLC9KK725yu9M8MQw9xhA"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'
import { config } from 'config'
import packageJson from './package.json'

const enabled = Boolean(config.sentry)

Sentry.init({
  release: `core@v${packageJson.version}`,
  enabled,
  dsn: config.sentry.dsn,
  environment: config.sentry.environment,
  tracesSampleRate: 1.0,
  profilesSampleRate: 1.0,
  integrations: [Sentry.browserProfilingIntegration()],

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  tracePropagationTargets: [
    /^https:\/\/app.egm-dev\.com/,
    'https://app.egm-dev.com',
    /^https:\/\/app.wealth-eg-dev\.com/,
    'https://app.wealth-eg-dev.com',
  ],
})
