import packageJson from '../package.json'
import moneyDevConfigJson from './money/dev.json'
import moneyLocalConfigJson from './money/local.json'
import moneyProdConfigJson from './money/prod.json'
import wealthDevConfigJson from './wealth/dev.json'
import wealthLocalConfigJson from './wealth/local.json'
import wealthProdConfigJson from './wealth/prod.json'

export enum AppVariant {
  MONEY = 'money',
  WEALTH = 'wealth',
}

export enum Environments {
  LOCAL = 'local',
  DEV = 'dev',
  PROD = 'prod',
}

export interface Config {
  env: Environments
  appName: string
  appVariant: AppVariant
  appVersion: string
  globalEnrollBaseUrl: string
  graphql: {
    url: string
    accessToken: string
  }
  smartystreets: {
    apiKey: string
  }
  zendeskArticles: {
    formCRS: string
    advPart2A2b: string
    adv2ABAndCRS: string
    initialExternalAccountLinkingFAQ: string
    wireTransferFundsFAQ: string
    wireTransferHowTo: string
    initialDepositFaq: string
  }
  launchDarkly: {
    clientSideId: string
  }
  fullStory?: {
    debug?: boolean
    devMode?: boolean
    orgId: string
    host: string
    script: string
    assetMapId?: string
  }
  sentry: {
    dsn: string
    orgSlug: string
    environment: string
  }
}

export const config = ((): Config => {
  const defaultConfig = (() => {
    if (process.env.NEXT_PUBLIC_APP_VARIANT === AppVariant.MONEY) {
      switch (process.env.NEXT_PUBLIC_ENVIRONMENT) {
        case Environments.LOCAL:
          return moneyLocalConfigJson
        case Environments.DEV:
          return moneyDevConfigJson
        case Environments.PROD:
          return moneyProdConfigJson
        default:
          return moneyLocalConfigJson
      }
    }
    if (process.env.NEXT_PUBLIC_APP_VARIANT === AppVariant.WEALTH) {
      switch (process.env.NEXT_PUBLIC_ENVIRONMENT) {
        case Environments.LOCAL:
          return wealthLocalConfigJson
        case Environments.DEV:
          return wealthDevConfigJson
        case Environments.PROD:
          return wealthProdConfigJson
        default:
          return wealthLocalConfigJson
      }
    }
    throw new Error('Invalid App Variant. Unable to source config.')
  })()
  return {
    ...defaultConfig,
    appVersion: packageJson.version,
  } as Config
})()
